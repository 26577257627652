import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Container from "layout/Container"

const Completed = ({ children, icon }) => (
  <Container isCentered>
    <center>
      <h1 className="title is-size-3 my-2">Thank you!</h1>
      <Container isCentered mobile={8} tablet={6} desktop={4}>
        <Img fluid={icon} alt="Success!" />
      </Container>
      {children}
      <Link to="/" className="button is-primary is-medium">
        Finish
      </Link>
    </center>
  </Container>
)

export default Completed
